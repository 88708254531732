import React from "react";

const Moments = ({ classicHeader, darkTheme }) => {

    const momentsDetails = [
        {
            yearRange: "1999",
            title: "Temp to Dev",
            place: "Naperville, IL",
            desc: "Fresh off my church mission and before I started college, I got a temp job. On my first day, I transitioned from data entry to programming, since I told the client that I knew of a better way to get the job done. Within three weeks, I had four computers automatically processing entries far faster than I could have done manually. The client was really happy and even threw me a party when it was time for me to leave!",
        },
        {
            yearRange: "2002",
            title: "Seeing the world",
            place: "NYC and Mexico City",
            desc: "After 9/11, while I was in college and learning about the world, I planned a solo trip to NYC and Mexico City, spending ten days in each. I met interesting people in the hostels, saw amazing sights, and felt a lot less provincial afterward.",
        },
        {
            yearRange: "2003",
            title: "The only voter",
            place: "Provo, UT",
            desc: "I was the only person in my precinct to vote in the election (a Provo City Council primary). If no one else will show up to vote, you can expect that I still will.",
        },
        {
          yearRange: "2009",
          title: "Muslim Prayer Day",
          place: "Washington, DC",
          desc: "The Muslim community scheduled a day of prayer at the U.S. Capitol. I knew there would be many protesters, so I decided to protest the protesters and welcome the Muslims. My wife helped me make signs, and I had a great experience talking to people and making them know they were welcome. This was one of the proudest moments in my life.",
        },
        {
            yearRange: "2010",
            title: "First children born",
            place: "Fairfax, VA",
            desc: "My wife was severely ill before and after the twins were born. The babies spent several months in the NICU and required significant care afterward. This was an intense time!",
        },
        {
            yearRange: "2013",
            title: "A summer to remember",
            place: "St Petersburg, FL",
            desc: "I became suddenly deaf in one ear. This was at the tail end of a summer of having a new baby and my wife's related health difficulties, toilet training toddlers, job problems, a house purchase and move, and new responsibilities at church.",
        },
        {
            yearRange: "2014-2018",
            title: "Morning adventures with the kids",
            place: "St Petersburg, FL",
            desc: "For several years, the kids and I had great adventures walking the trail and then taking the PSTA bus to school together in the mornings. We talked and played and had irreplaceable experiences during our morning Daddy time. I later collected the photos and messages I sent my wife during the time and made a book of it all for the family.",
        },
        {
            yearRange: "2020",
            title: "Mountains",
            place: "North Carolina",
            desc: "After being cooped up during Covid, we broke out of our cabin fever by hitting the road four weekends in a row! We drove all over the mountains of western North Carolina and saw amazing sights. We've since done many other trips, and even had a white Christmas on a farm in Tennessee, where the kids snow tubed and played with the farm dog!",
        },
        {
            yearRange: "2021",
            title: "Dream job",
            place: "Charlotte, NC",
            desc: "Dissatisfied with work, I asked myself what I really wanted to do professionally. I decided the answer was \"electric utility modeling and planning software\". After taking some time to finish my duck curve demo web site, I went and got a job doing exactly that!",
        },
  ];


  return (
    <section
      id="moments"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Personal History
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Memorable Moments
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Education */}
          <div className="col-md-12">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              
            </h2>
            {momentsDetails.length > 0 &&
              momentsDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Moments;
