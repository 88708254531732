import React from "react";

const Works = ({ classicHeader, darkTheme }) => {
  // works details
  const works = [
    {
      name: "Nissus - Young Adult SF Novel",
      desc: "What makes us human, and what might make us not quite human anymore? Nissus is a deep dive into a possible future where the characters are confronted with these questions.",
      icon: "fas fa-book-open",
      link: "https://nissusnovel.com",
    },
    {
      name: "Metric Information",
      desc: "Inertia and historical accident - those are the only reasons we have been left behind the larger world, stuck using a hodgepodge of colloquial units of measure. It does not have to be that way, and it won't be that way forever. The transition can and will eventually happen.",
      icon: "fas fa-ruler",
      link: "https://themetricsystem.info",
    },
    {
      name: "Substack - Civics and Policy in Action",
      desc: "Insightful perspectives on political engagement, climate and energy policy, and global affairs - guided by civic values and ethical principles.",
      icon: "fas fa-bullhorn",
      link: "https://civicsandpolicyinaction.substack.com/",
    },
    {
      name: "Duck Curve Analysis site",
      desc: "Analyze real data about electricity use at my house in the context of the duck curve - the shape that net electricity demand can take when significant amounts of solar generation are present. Demonstrating both energy topics as well as some programming skills, this site was built with C#, Angular, and dynamic SVG.",
      icon: "fas fa-solar-panel",
      link: "https://duck-curve-analysis.com",
    },
    {
      name: "Short Fiction",
      desc: "I have written several short stories addressing themes such as futurism, post-humanism, space colonization, etc. I may publish a selection of these after my novel is released.",
      icon: "fas fa-scroll",
    },
    {
      name: "Carbon Takeback Ratio",
      desc: "Coming eventually",
      icon: "fas fa-scale-balanced",
    },
  ];

  return (
    <section
      id="works"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-white-50  opacity-1" : "text-light  opacity-4")
            }
          >
            Writings, etc.
          </h2>
          <p
            className={
              "text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Works
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        {/* content start */}
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              {works.length > 0 &&
                works.map((work, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="featured-box style-3 mb-5">
                      <div
                        className={
                          "featured-box-icon text-primary  shadow-sm rounded " +
                          (darkTheme ? "bg-dark-1" : "bg-white")
                        }
                      >
                        <a href={work.link} target="_blank" rel="noreferrer"><i className={work.icon} /></a>
                      </div>
                      <h3 className={darkTheme ? "text-white" : ""}>
                        {work.name}
                      </h3>
                      <p
                        className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                      >
                        {work.desc}
                        <br />
                        {work.link?.length > 0 && 
                          <a href={work.link} target="_blank" rel="noreferrer">Link <i className="fas fa-up-right-from-square" /></a>
                        }
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* content end */}
      </div>
    </section>
  );
};

export default Works;
